import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { keyBy } from 'lodash';
import React, { useState } from 'react';
import { Menu } from '../../components/AdminMenu';
import {
  IColumn,
  ID_TO_ROW_KEY,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS
} from '../../components/GroupableList';
import { PlainLoader } from '../../components/Loader';
import { ProductHuntBanner } from '../../components/ProductHuntBanner';
import { onlyForAdmins } from '../../components/ProtectedAdminPage';
import { SEO } from '../../components/SEO';
import { FUNCTIONS } from '../../constants';
import { useDialogState } from '../../hooks/useDialogState';
import { useErrorLogger } from '../../hooks/useErrorLogger';
import Layout, { Wrapper } from '../../layouts/Layout';
import { useAllBillingUsers, useAllUsers } from '../../services/admin-users';
import { useAllWebsites } from '../../services/admin-websites';
import { callFirebaseFunction } from '../../services/cf';
import {
  combineLoadingValues3,
  useMappedLoadingValue
} from '../../services/db';
import styled from '../../styled';
import { Doc } from '../../types/Document';
import { BillingUser, User } from '../../types/User';
import { Website } from '../../types/Website';

const Body = styled('div')`
  margin: ${(p) => p.theme.spacing(4)}px;
`;

export const CanvasBar = styled('div')((p) => ({
  marginBottom: p.theme.spacing() * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  color: p.theme.palette.text.secondary,
  fontSize: p.theme.custom.fontSize.m
}));

type D = {
  id: string;
  user: User;
  billingUser: BillingUser;
  websites: Doc<Website>[];
};
type ColumnName =
  | 'name'
  | 'email'
  | 'websites'
  | 'plan'
  | 'joinedAt'
  | 'actions';
type Column = IColumn<D, ColumnName>;

const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => `${d.user.lastName} ${d.user.firstName}`,
    sortable: true,
    align: 'left',
    width: 200,
    flexGrow: 2
  },
  {
    key: 'email',
    head: () => 'Email',
    cell: (d) => d.user.email,
    sortable: true,
    align: 'left',
    width: 200,
    flexGrow: 2
  },
  {
    key: 'websites',
    head: () => 'Websites',
    cell: (d) => d.websites.map((x) => x.data.url).join(', '),
    sortable: false,
    align: 'left',
    width: 200,
    flexGrow: 2
  },
  {
    key: 'plan',
    head: () => 'Plan',
    cell: (d) => d.billingUser.plan,
    sortable: true,
    align: 'left',
    width: 200,
    flexGrow: 3
  },
  {
    key: 'joinedAt',
    head: () => 'Joined At',
    cell: (d) => d.user.joinDate.toDate().toLocaleString(),
    sortable: true,
    align: 'left',
    width: 200,
    flexGrow: 3
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d) => null,
    sortable: false,
    align: 'left',
    width: 80,
    flexGrow: 0
  }
];
const SORTERS: ItemSorters<D> = {
  name: {
    key: 'name',
    items: { sort: (d) => `${d.user.lastName} ${d.user.firstName}`, dir: 'asc' }
  },
  total: {
    key: 'email',
    items: { sort: (d) => d.user.email, dir: 'asc' }
  },
  plan: {
    key: 'plan',
    items: { sort: (d) => d.billingUser.plan || '', dir: 'asc' }
  },
  joinDate: {
    key: 'joinDate',
    items: { sort: (d) => d.user.joinDate.toMillis(), dir: 'desc' }
  }
};
const DEFAULT_SORTER = SORTERS.name;

const groupWebsitesByUserId = (ws: Doc<Website>[]) => {
  return ws.reduce<{ [userId: string]: Doc<Website>[] }>((m, w) => {
    w.data.userIds.forEach((userId) => {
      const websites = (m[userId] = m[userId] || []);
      websites.push(w);
    });
    return m;
  }, {});
};

type CreateNewUserState = {
  email: string;
  firstName: string;
  lastName: string;
  location: string;
  submitting: boolean;
};

const INITIAL_CREATE_NEW_USER_STATE: CreateNewUserState = {
  email: '',
  firstName: '',
  lastName: '',
  location: '',
  submitting: false
};

const FormRowSingle = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const FormRowDouble = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const CreateNewUserDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [state, setState] = useState<CreateNewUserState>(
    INITIAL_CREATE_NEW_USER_STATE
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create a new user</DialogTitle>
      <form
        onSubmit={async (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setState((s) => ({ ...s, submitting: true }));
          const res = await callFirebaseFunction<{
            // also user and billingUser
            password: string;
          }>(FUNCTIONS._createNewUser, {
            email: state.email,
            firstName: state.firstName,
            lastName: state.lastName,
            location: state.location
          });
          console.log('NEW USER CREATED', res);
          setState(INITIAL_CREATE_NEW_USER_STATE);
          onClose();
          alert(`Password for ${state.email}: ${res.password}`);
        }}
      >
        <DialogContent>
          <FormRowSingle>
            <TextField
              variant="outlined"
              label="Email"
              value={state.email}
              onChange={(ev) => {
                const email = ev.target.value;
                setState((s) => ({ ...s, email }));
              }}
              fullWidth
              required
            />
          </FormRowSingle>
          <FormRowDouble>
            <TextField
              variant="outlined"
              label="First Name"
              value={state.firstName}
              onChange={(ev) => {
                const firstName = ev.target.value;
                setState((s) => ({ ...s, firstName }));
              }}
              fullWidth
            />
            <TextField
              variant="outlined"
              label="Last Name"
              value={state.lastName}
              onChange={(ev) => {
                const lastName = ev.target.value;
                setState((s) => ({ ...s, lastName }));
              }}
              fullWidth
            />
          </FormRowDouble>
          <FormRowSingle>
            <TextField
              variant="outlined"
              label="Location"
              value={state.location}
              onChange={(ev) => {
                const location = ev.target.value;
                setState((s) => ({ ...s, location }));
              }}
              fullWidth
            />
          </FormRowSingle>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={!state.email || state.submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            {state.submitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const UsersPage = () => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const [users, loading, error] = useMappedLoadingValue(
    combineLoadingValues3(
      useAllUsers(),
      useAllBillingUsers(),
      useAllWebsites()
    ),
    ([users, billingUsers, websites]) => {
      const billingUsersById = keyBy(billingUsers, (b) => b.id);
      const websitesByUserId = groupWebsitesByUserId(websites);
      return users.map<D>((u) => {
        const billingUser = billingUsersById[u.id];
        return {
          id: u.id,
          user: u.data,
          billingUser: billingUser?.data || {
            customerId: '',
            subscriptionId: '',
            plan: null
          },
          websites: websitesByUserId[u.id] || []
        };
      });
    }
  );
  useErrorLogger(error);

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Admin"
        siteUrl="https://bloggingfordevs.com"
        description="Admin section, lookout!"
        pathname="admin/users"
        noIndex
      />
      <Wrapper>
        <Menu />
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Users
          </Typography>
        </div>
      </Wrapper>
      <Body>
        <CanvasBar>
          <div />
          <Button variant="contained" color="primary" onClick={openDialog}>
            Create new user...
          </Button>
        </CanvasBar>
        {loading && <PlainLoader height={500} />}
        {users && (
          <RowsRenderer
            variant="contained"
            columns={COLUMNS}
            rows={users}
            rowToKey={ID_TO_ROW_KEY}
            sorter={DEFAULT_SORTER}
            sortDirection={DEFAULT_SORTER.items.dir}
            renderHead={true}
            chunkSize={30}
            rootMargin="400px"
            otherProps={undefined}
            rowHeight={ROW_HEIGHTS.dense}
          />
        )}
      </Body>
      <CreateNewUserDialog open={dialogOpen} onClose={closeDialog} />
    </Layout>
  );
};

export default onlyForAdmins(UsersPage);
